import { observer } from "mobx-react-lite";
import React, { isValidElement, ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import image_deprecated from "@Core/assets/images/illustrations/deprecated.jpg";
import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import image_phone from "@Core/assets/images/illustrations/questionnaire_completed.jpg";
import { Video } from "@Core/components/Components";
import { Block, Button, Step } from "@Core/components/UI";
import { QuestionnaireFor } from "@Core/constants/ENUMS";
import QuestionnaireTokenService, { TOKEN_STATUS } from "@Core/services/QuestionnaireTokenService";

import { EmployeeService } from "@PNI/services";
import { usePNIStores } from "@PNI/stores";

import styles from "./DigitalQuestionnaireIntroduction.module.scss";

export interface IntroductionStepProps {
  title: string;
  description: string | ReactElement;
  image?: string;
  video?: string;
  children?: ReactNode | ((args: { goToNextStep: () => void }) => ReactNode);
  nextStep?: () => void;
}

const toArray = <T,>(value: T) => {
  if (Array.isArray(value)) {
    return value;
  }

  return [value];
};

const IntroductionStep = ({ title, description, image, video, children, nextStep }: IntroductionStepProps) => {
  return (
    <div className={styles.Step}>
      {video && <Video url={video} />}
      {image && (
        <div className={styles.Image}>
          <LazyLoad>
            <img src={image} alt={title} />
          </LazyLoad>
        </div>
      )}
      <h3>{title}</h3>

      {typeof description === "string" ? <p dangerouslySetInnerHTML={{ __html: description }}></p> : description}

      {toArray(children).map((child) => {
        if (typeof child === "function") {
          return child({ goToNextStep: nextStep });
        }

        return child;
      })}
    </div>
  );
};

export interface IntroductionProps {
  onComplete?: () => void;
  children: ReactNode[];
  status?: TOKEN_STATUS;
  type?: QuestionnaireFor;
  onValidated: (args: { status: TOKEN_STATUS; phoneNumber?: string; token: string }) => void;
}

const IntroductionRoot = observer(({ onComplete, type, children, status, onValidated }: IntroductionProps) => {
  const { EmployeeStore } = usePNIStores();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentToken = params.get("token");

    if (currentToken) {
      (async () => {
        await QuestionnaireTokenService.validateToken(currentToken)
          .then((response: any) => {
            onValidated({
              status: response.status,
              phoneNumber: response.phone_number_obfuscated,
              token: currentToken,
            });
          })
          .catch(() => {
            history.push("/");
          });
      })();
    }
  }, [history]);

  const [linkRequested, setLinkRequested] = useState(false);

  // Check if token is valid, if so store token to the store

  const onLinkRequest = async () => {
    const params = new URLSearchParams(window.location.search);
    const currentToken = params.get("token");

    if (currentToken) {
      try {
        setLinkRequested(true);
        toast.success(t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.DEPRATECTED.REQUESTED`));

        await EmployeeService.resend(currentToken);
      } catch (e) {
        setLinkRequested(false);
      }
    }
  };

  const previousStep = () => {
    setStep((oldStep) => oldStep - 1);
  };

  const nextStep = async () => {
    setStep((oldStep) => oldStep + 1);

    if (!EmployeeStore.user) {
      await EmployeeStore.load();
    }

    if (step === children.length - 1) {
      onComplete?.();
    }
  };

  const getStep = (step: number) => {
    const currentStep = children[step];

    if (isValidElement(currentStep)) {
      return (
        <>
          <DigitalQuestionnaireIntroduction.Step {...currentStep.props} nextStep={nextStep}>
            {currentStep.props.children}

            {step !== 0 && (
              <div className={styles.Navigation}>
                <Button
                  primary
                  link
                  disabled={step <= 1}
                  hover
                  iconLeft="fa-long-arrow-left"
                  onClick={() => previousStep()}
                >
                  {t("SHARED.PREVIOUS")}
                </Button>

                <Button
                  primary
                  link={step !== children.length - 1}
                  hover
                  iconRight="fa-long-arrow-right"
                  onClick={() => nextStep()}
                >
                  {step !== children.length - 1 && <span>{t("SHARED.NEXT")}</span>}
                  {step === children.length - 1 && <span>{t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.PROCEED`)}</span>}
                </Button>
              </div>
            )}
          </DigitalQuestionnaireIntroduction.Step>
        </>
      );
    }
  };

  const getHandledByPhone = () => {
    return (
      <IntroductionStep
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.PHONE.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.PHONE.DESCRIPTION`)}
        image={image_phone}
        nextStep={nextStep}
      />
    );
  };

  const getDeprecated = () => {
    return (
      <IntroductionStep
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.DEPRATECTED.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.DEPRATECTED.DESCRIPTION`)}
        image={image_deprecated}
        nextStep={nextStep}
      >
        <Button primary onClick={onLinkRequest} disabled={linkRequested}>
          {t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.DEPRATECTED.BUTTON`)}
        </Button>
      </IntroductionStep>
    );
  };

  const getAlreadyCompleted = () => {
    return (
      <IntroductionStep
        title={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.COMPLETED.TITLE`)}
        description={t(`DIGITAL_QUESTIONNAIRE.INTRODUCTION.COMPLETED.DESCRIPTION`)}
        image={image_question_exclamation}
        nextStep={nextStep}
      />
    );
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <Block name={t(`DIGITAL_QUESTIONNAIRE.${type}.TITLE`)} overflow center className={styles.Introduction}>
            {status === TOKEN_STATUS.TOKEN_STATUS_VALID && (
              <Step className={styles.Progress} step={step + 1} steps={children.length} dots hide />
            )}

            {status === TOKEN_STATUS.STATUS_HANDLED_BY_PHONE && getHandledByPhone()}
            {status === TOKEN_STATUS.TOKEN_STATUS_EXPIRED && getDeprecated()}
            {status === TOKEN_STATUS.TOKEN_STATUS_PROCESSED && getAlreadyCompleted()}
            {status === TOKEN_STATUS.TOKEN_STATUS_VALID && getStep(step)}
          </Block>
        </div>
      </div>
    </div>
  );
});

export const DigitalQuestionnaireIntroduction = Object.assign(IntroductionRoot, { Step: IntroductionStep });
